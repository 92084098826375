import PropTypes from 'prop-types';
import { createContext, useEffect, useState } from 'react';

export const CartContext = createContext(null);

export const CartContextContainer = ({ children }) => {
  const [cartDetails, setCartDetails] = useState(() => {
    const savedCartDetails = localStorage.getItem('cartDetails');
    return savedCartDetails ? JSON.parse(savedCartDetails) : [];
  });

  useEffect(() => {
    localStorage.setItem('cartDetails', JSON.stringify(cartDetails));
  }, [cartDetails]);

  const removeCart = (index) => {
    setCartDetails((prev) => {
      const newCartDetails = [...prev];
      newCartDetails.splice(index, 1);
      return newCartDetails;
    });
  };

  return <CartContext.Provider value={{ cartDetails, setCartDetails, removeCart }}>{children}</CartContext.Provider>;
};

CartContextContainer.propTypes = {
  children: PropTypes.any,
};
