import axios from 'axios';
import { useContext, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// @mui
import { LoadingButton } from '@mui/lab';
import { IconButton, InputAdornment, Stack, TextField, Typography } from '@mui/material';
// components
import Iconify from '../../../components/iconify';
import { BASE_URL } from '../../../constant';
import { AuthContext } from '../../../context/AuthContext';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();
  const { login } = useContext(AuthContext);
  const [userName, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [disable, setDisable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const location = useLocation();
  const sessionExpired = location.state?.sessionExpired || false;

  const handleClick = async (e) => {
    e.preventDefault();
    // Trim the input values
    const trimmedUsername = userName.trim();
    const trimmedPassword = password.trim();

    // Validate both fields are filled
    if (trimmedUsername === '' || trimmedPassword === '') {
      setError('Please enter both username and password.');
      return;
    }
    try {
      setDisable(true);
      setLoading(true);
      // Send the POST request
      const { data } = await axios.post(`${BASE_URL}login`, {
        userName: trimmedUsername,
        password: trimmedPassword,
      });
      login(data);
      navigate('/dashboard', { state: { sessionExpired: false }, replace: true });
      setDisable(false);
      setLoading(false);
    } catch (error) {
      // Handle error
      setError('Invalid username or password.');
      setDisable(false);
      setLoading(false);
    }
  };

  return (
    <>
      {sessionExpired && (
        <Typography mb={2} variant="overline" display="block" color="error">
          Session Expired. Please Login In
        </Typography>
      )}
      <form onSubmit={handleClick}>
        <Stack spacing={3}>
          {error && (
            <Typography mb={2} variant="overline" display="block" color="error">
              {error}
            </Typography>
          )}
          <TextField
            style={{ backgroundColor: '#fff', borderRadius: '10px' }}
            value={userName}
            name="userName"
            label="User Name"
            onChange={(e) => setUsername(e.target.value)}
          />

          <TextField
            style={{ backgroundColor: '#fff', borderRadius: '10px' }}
            name="password"
            label="Password"
            value={password}
            type={showPassword ? 'text' : 'password'}
            onChange={(e) => setPassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Stack>

        {/* <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <Checkbox name="remember" label="Remember me" />
        <Link variant="subtitle2" underline="hover">
          Forgot password?
        </Link>
      </Stack> */}

        <div style={{ marginTop: '12px' }}>
          <LoadingButton
            fullWidth
            disabled={disable}
            size="large"
            type="submit"
            variant="contained"
            onClick={handleClick}
          >
            {loading ? 'Authenticating...' : 'Login'}
          </LoadingButton>
        </div>
      </form>
    </>
  );
}
