import React from 'react'
import LegacyLinks from '../../components/legacyLinks/LegacyLinks'

const links = [
    {name: "Jewellery", to: "/dashboard/inventory/jewellery", icon:"mdi:gold"},
    {name: "Watch", to: "/dashboard/inventory/watch", icon:"game-icons:gold-bar"},
    {name: "Silver", to: "/dashboard/inventory/silver", icon:"game-icons:silver-bullet"},
]

const InventoryLinks = () => (
    <LegacyLinks links={links} />
  )

export default InventoryLinks