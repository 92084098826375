import jwtDecode from 'jwt-decode';
import PropTypes from 'prop-types';
import { createContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const AuthContext = createContext(null);

export const AuthContextContainer = ({ children }) => {
  const [authData, setAuthData] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const storedUser = sessionStorage.getItem('tokenDetails');
    if (storedUser) {
      const token = JSON.parse(storedUser);
      const decodedToken = jwtDecode(token.token);
      const currentTime = Math.floor(Date.now() / 1000);
      if (decodedToken.exp < currentTime) {
        // Token has expired, navigate to login page with session expired error
        sessionStorage.removeItem('tokenDetails');
        navigate('/login', { state: { sessionExpired: true }, replace: true });
      } else {
        setAuthData(token);
      }
    }
  }, [navigate]);

  const login = (response) => {
    sessionStorage.setItem('tokenDetails', JSON.stringify(response));
    setAuthData(response);
  };

  const getSession = () => {
    const token = JSON.parse(sessionStorage.getItem('tokenDetails'));
    return token;
  };

  const logout = () => {
    setAuthData(null);
    sessionStorage.removeItem('tokenDetails');
    navigate('/login', { replace: true });
  };

  return <AuthContext.Provider value={{ authData, login, logout, getSession }}>{children}</AuthContext.Provider>;
};

AuthContextContainer.propTypes = {
  children: PropTypes.any,
};
