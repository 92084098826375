import api from '../axios/api';

export const getAllbarOrder = async () => {
  const { data } = await api.get('barOrder');
  return data;
};

export const createbarOrder = async ({ customer, orders }) => {
  const { data } = await api.post('barOrder', { customer, orders });
  return data;
};

export const getSinglebarOrder = async ({ queryKey }) => {
  const [_key, { id }] = queryKey;
  const { data } = await api.get(`barOrder/${id}`);
  return data;
};

export const getSinglebarOrderEdited = async ({ queryKey }) => {
  const [_key, { id }] = queryKey;
  const { data } = await api.get(`barOrder/${id}/edited`);
  return data;
};

export const cancellbarOrder = async (id) => {
  // console.log("from api",user)
  // console.log("from api id",id)
  const { data } = await api.post(`barOrder/${id.id}/cancel`,id.user);
  return data;
};

export const updatebarOrder = async ({ id, barOrder }) => {
  const { data } = await api.put(`barOrder/${id}`, barOrder);
  return data;
};

export const deleteBarORder = async ({userId, user}) => {
  const { data } = await api.post(`barOrder/${userId}/deleteOrder`,user);
  return data;
};
