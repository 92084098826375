
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import LegacyCard from './LegacyCard';




const LegacyLinks = ({links}) => (
    <Grid container spacing={3} mt={3}>
     {links?.map(link => <LegacyCard name={link.name} icon={link.icon} to={link.to} key={link.name}  />)}
     </Grid>
  )

  LegacyLinks.propTypes = {
    links: PropTypes.any,
  };

export default LegacyLinks