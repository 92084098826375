import { Container, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
// hooks
import useResponsive from '../hooks/useResponsive';
// components
// import Logo from '../components/logo';
// sections
import Image from '../anugrah.jpg';
import { LoginForm } from '../sections/auth/login';
// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  // [theme.breakpoints.up('md')]: {
  display: 'flex',
  alignItems: 'center',
  backgroundImage: `url(${Image})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  boxSizing: 'border-box',
  minHeight: '100vh',
}));

// }

// const StyledSection = styled('div')(({ theme }) => ({
//   maxWidth: 480,
//   display: 'flex',
//   flexDirection: 'column',
//   justifyContent: 'center',
//   boxShadow: theme.customShadows.card,
//   backgroundColor: theme.palette.background.default,
// }));

const StyledContent = styled('div')(({ theme }) => ({
  backgroundColor: `rgba(255, 255, 255, 0.8)`,
  maxWidth: 480,
  margin: 'auto',
  borderRadius: '18px',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: '60px',

  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2), // Responsive padding for small screens (mobile)
  },
}));

// ----------------------------------------------------------------------

export default function LoginPage() {
  const mdUp = useResponsive('up', 'md');

  return (
    <>
      <StyledRoot>
        {/* <Logo
          sx={{
            position: 'fixed',
            top: { xs: 16, sm: 24, md: 40 },
            left: { xs: 16, sm: 24, md: 40 },
          }}
        /> */}

        {/* {mdUp && (
          <StyledSection>
            <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
              Hi, Welcome Back
            </Typography>
            <img src="/assets/illustrations/illustration_login.png" alt="login" />
          </StyledSection>
        )} */}

        <Container>
          <StyledContent>
            <div>
              <img
                src="/assets/images/company/Anugraha_logo.png"
                // src="/assets/images/company/you-logo-here.png"
                alt="login"
                style={{ padding: '12px', marginBottom: '16px' }}
              />
            </div>
            <LoginForm />
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}
