import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

const ErrorComponent = ({ error }) => (
  <Typography mb={2} variant="overline" display="block" color="error">
    {error?.response?.data?.message}
  </Typography>
);

ErrorComponent.propTypes = {
  error: PropTypes.object,
};

export default ErrorComponent;
