import PropTypes from 'prop-types';
// @mui
import { Badge, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const CartHeader = ({ cartDetails }) => {
  const navigate = useNavigate();
  return (
    <>
      {' '}
      <IconButton color="primary" onClick={() => navigate('/dashboard/inventory/cart/')} sx={{ width: 40, height: 40 }}>
        <Badge badgeContent={cartDetails?.length} color="error">
          <img src="/assets/icons/ic_notification_shipping.svg" alt="cart" />
        </Badge>
      </IconButton>
    </>
  );
};
CartHeader.propTypes = {
  cartDetails: PropTypes.array,
};

export default CartHeader;
