import { createContext, useState } from 'react';
import PropTypes from 'prop-types';

export const BillingContext = createContext(null);
// this context is for bill page to bill print or dispatch page

export const BillingContextContainer = ({ children }) => {
  const [billingDetails, setBillingDetails] = useState([]);

  return <BillingContext.Provider value={{ billingDetails, setBillingDetails }}>{children}</BillingContext.Provider>;
};

BillingContextContainer.propTypes = {
  children: PropTypes.any,
};
