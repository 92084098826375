export const preset = 'toclqyod';
export const cloud = 'https://api.cloudinary.com/v1_1/ddkv32qqz/image/upload';

export const paymentTypes = [
  { value: 'Gold' },
  { value: 'Cash' },
  { value: 'Credit Card' },
  { value: 'Online' },
  { value: 'Cheque' },
];

// export const BASE_URL = 'http://localhost:3500/api/v1/';
// export const IMG_URL = 'http://localhost:3500';

// export const BASE_URL = 'https://gurkhacrm.onrender.com/api/v1/';
// export const IMG_URL = 'https://gurkhacrm.onrender.com';

// export const BASE_URL = 'https://anugracrm.onrender.com/api/v1/';
// export const IMG_URL = 'https://anugracrm.onrender.com';

export const BASE_URL = 'https://backend-a1ga.onrender.com/api/v1/';
export const IMG_URL = 'https://backend-a1ga.onrender.com';

// export const BASE_URL = 'https://anugrah-backend.onrender.com/api/v1/';
// export const IMG_URL = 'https://anugrah-backend.onrender.com';

// export const BASE_URL = 'http://kcw0koo.141.136.42.201.sslip.io:3500/api/v1/';
// export const IMG_URL = 'http://kcw0koo.141.136.42.201.sslip.io';
