import { HelmetProvider } from 'react-helmet-async';
import { HashRouter } from 'react-router-dom';
// routes
import { useQuery } from '@tanstack/react-query';
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import api from './axios/api';
import { StyledChart } from './components/chart';
import ScrollToTop from './components/scroll-to-top';
import { AuthContextContainer } from './context/AuthContext';
import { BarBillingContainer } from './context/BarBillingContext';
import { BillingContextContainer } from './context/BillingContext';
import { CartContextContainer } from './context/CartContext';
import { InventoryBillingContextContainer } from './context/InventoryBillingContext';
import { OrderToProcessContext } from './context/OrderToProcessContext';
import { ProcessToBillingContext } from './context/ProcessToBillingContext';

// ----------------------------------------------------------------------

export default function App() {
  const { isFetching } = useQuery({
    queryKey: ['todos'],
    queryFn: async () => {
      const res = await api.get('wakeup');
      return res.data;
    },
    // Refetch the data every 14 minutes
    refetchInterval: 840000,
  });
  return (
    <HelmetProvider>
      <HashRouter>
        <ThemeProvider>
          <ScrollToTop />
          <StyledChart />
          <AuthContextContainer>
            <CartContextContainer>
              <InventoryBillingContextContainer>
                <BillingContextContainer>
                  <ProcessToBillingContext>
                    <OrderToProcessContext>
                      <BarBillingContainer>
                        <Router />
                      </BarBillingContainer>
                    </OrderToProcessContext>
                  </ProcessToBillingContext>
                </BillingContextContainer>
              </InventoryBillingContextContainer>
            </CartContextContainer>
          </AuthContextContainer>
        </ThemeProvider>
      </HashRouter>
    </HelmetProvider>
  );
}
