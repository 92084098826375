import { Box, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import Iconify from '../iconify/Iconify';

const LegacyCard = ({ name, icon, to }) => (
  <Grid item xs={12} md={4}>
    <Link to={to} style={{color: "#000000", textDecoration: "none"}}>
      <Box
        sx={{
          padding: '20px',
          textAlign: 'center',
          display: 'flex',
          backgroundColor: '#ffffff',
          borderRadius: '10px',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Iconify icon={icon} />
        <Typography variant="h4" sx={{ paddingLeft: '16px' }}>
          {name}
        </Typography>
      </Box>
    </Link>
  </Grid>
);

LegacyCard.propTypes = {
  name: PropTypes.string,
  icon: PropTypes.string,
  to: PropTypes.string,
};

export default LegacyCard;
