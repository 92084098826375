import { createContext, useState } from 'react';
import PropTypes from 'prop-types';

export const InventoryBillingContext = createContext(null);
// this context is for bill page to bill print or dispatch page

export const InventoryBillingContextContainer = ({ children }) => {
  const [billingDetails, setBillingDetails] = useState([]);

  return (
    <InventoryBillingContext.Provider value={{ billingDetails, setBillingDetails }}>
      {children}
    </InventoryBillingContext.Provider>
  );
};

InventoryBillingContextContainer.propTypes = {
  children: PropTypes.any,
};
