import React from 'react'
import LegacyLinks from '../../components/legacyLinks/LegacyLinks'

const links = [
    {name: "Jewellery Orders", to: "/dashboard/order/jewellery", icon:"mdi:gold"},
    {name: "Bar Orders", to: "/dashboard/order/bar", icon:"game-icons:gold-bar"},
]

const OrderLists = () => (
    <LegacyLinks links={links} />
  )

export default OrderLists