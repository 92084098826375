import api from '../axios/api';

export const getLogo = async () => {
  const { data } = await api.get('logo');
  return data;
};

export const updateLogo = async ({ id, logo }) => {
  const { data } = await api.put(`logo/${id}`, { logo });
  return data;
};
