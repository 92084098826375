import React from 'react';
import ErrorPageRedirection from './pages/ErrorPageRedirection';

// ----------------------------------------------------------------------

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
    this.resetError = this.resetError.bind(this);
  }

  componentDidCatch(error, errorInfo) {
    // Set the state to indicate an error
    this.setState({ hasError: true });

    // You can log the error or send it to an error tracking service here
    console.error(error, errorInfo);
  }

  resetError() {
    this.setState({ hasError: false });
  }

  render() {
    if (this.state.hasError) {
      // Render the custom error page component when an error occurs
      return <ErrorPageRedirection resetError={this.resetError} />;
    }

    // Render the children (your app) if there's no error
    return this.props.children;
  }
}

export default ErrorBoundary;
