import { createContext, useState } from 'react';
import PropTypes from 'prop-types';

export const BarBillingContext = createContext(null);

export const BarBillingContainer = ({ children }) => {
  const [billingDetails, setBillingDetails] = useState({});

  return (
    <BarBillingContext.Provider value={{ billingDetails, setBillingDetails }}>{children}</BarBillingContext.Provider>
  );
};

BarBillingContainer.propTypes = {
  children: PropTypes.any,
};
