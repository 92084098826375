import { Helmet } from 'react-helmet-async';

import { Container, Typography } from '@mui/material';

import { useContext } from 'react';
import { AuthContext } from '../context/AuthContext';
import DashboardAdminPage from './dashboardAdmin/DashboardAdminPage';
import DashboardUserPage from './dashboardUser/DashboardUserPage';
// ----------------------------------------------------------------------

export default function DashboardAppPage() {
  const { authData } = useContext(AuthContext);

  const content = authData?.role === 'admin' ? <DashboardAdminPage /> : <DashboardUserPage />;

  return (
    <>
      <Helmet>
        <title> Dashboard | </title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Hi, Welcome back {authData?.fullName}
        </Typography>
        {content}
      </Container>
    </>
  );
}
