import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Grid, Typography } from '@mui/material';
import DashboardUserBarComplete from './DashboardUserBarComplete';
import DashboardUserBarIncomplete from './DashboardUserBarIncomplete';
import ErrorComponent from '../../components/error/ErrorComponent';
import Loader from '../../components/loader/Loader';
import { getAllbarOrder } from '../../api/barOrderApi';

const DashboardUserBar = () => {
  const { data, isLoading, error, isError } = useQuery(['barOrder'], getAllbarOrder);

  const completedTask = useMemo(() => data?.filter((task) => task.status === 'Transaction Completed'), [data]);

  const InCompletedTask = useMemo(() => data?.filter((task) => task.status !== 'Transaction Completed'), [data]);

  if (isError) {
    return <ErrorComponent error={error} />;
  }
  if (isLoading) {
    return <Loader />;
  }
  return (
    <Grid container spacing={2}>
      <Grid item sm={12} md={6}>
        <Typography variant="h6" mb={4}>
          Completed Task
        </Typography>
        <DashboardUserBarComplete completedTask={completedTask} />
      </Grid>
      <Grid item sm={12} md={6}>
        <Typography variant="h6" mb={4}>
          InCompleted Task
        </Typography>
        <DashboardUserBarIncomplete InCompletedTask={InCompletedTask} />
      </Grid>
    </Grid>
  );
};

export default DashboardUserBar;
