import { createContext, useState } from 'react';
import PropTypes from 'prop-types';

export const OrderProcessContext = createContext(null);

export const OrderToProcessContext = ({ children }) => {
  const [processDetails, setProcessDetails] = useState({});

  return (
    <OrderProcessContext.Provider value={{ processDetails, setProcessDetails }}>
      {children}
    </OrderProcessContext.Provider>
  );
};

OrderToProcessContext.propTypes = {
  children: PropTypes.any,
};
