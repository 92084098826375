import { Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useQuery } from '@tanstack/react-query';
import { getDashboardCount, getDashboardSales, getDashboardTasks } from '../../api/dashboardApi';
import LegacyLinks from '../../components/legacyLinks/LegacyLinks';
import { AppCurrentVisits, AppWidgetSummary } from '../../sections/@dashboard/app';

const DashboardAdminPage = () => {
  const theme = useTheme();
  const { data } = useQuery(['dashboardCount'], getDashboardCount);
  const { data: sales } = useQuery(['dashboardSales'], getDashboardSales);
  const { data: tasks } = useQuery(['dashboardTasks'], getDashboardTasks);

  const links = [
    {name: "Users", to: "/dashboard/users", icon:"mdi:users"},
    {name: "Customers", to: "/dashboard/customers", icon:"raphael:people"},
    {name: "Factory", to: "/dashboard/factory", icon:"material-symbols:factory"},
    {name: "Orders", to: "/dashboard/order-links", icon:"mingcute:pencil-ruler-fill"},
    {name: "Process-list", to: "/dashboard/order/process", icon:"fa-solid:sync"},
    {name: "Billings", to: "/dashboard/order/billing", icon:"fa6-solid:file-invoice-dollar"},
    {name: "Dispatch", to: "/dashboard/order/dispatch", icon:"mdi:courier-check"},
    {name: "Inventory", to: "/dashboard/inventory-links", icon:"ic:baseline-inventory"},
    {name: "Reports", to: "/dashboard/report", icon:"iconoir:reports"},
    {name: "Profile", to: "/dashboard/profile", icon:"fa-solid:user-cog"},
    {name: "Cancelled Invoice", to: "/dashboard/cancel", icon:"material-symbols:delete"},
]

  let grandTotal = 0;
  if (sales) {
    grandTotal = [
      ...sales?.billingTotal,
      ...sales?.barBillingTotal,
      ...sales?.jewelleryInventoryBillingTotal,
      ...sales?.watchInventoryBillingTotal,
      ...sales?.silverInventoryBillingTotal,
    ]?.reduce((acc, { total }) => acc + total, 0);
  }

  const totalSales = `Total Sales £${grandTotal?.toFixed(3)}`;
  const totalJewelleryTasks = `Total JewelleryOrder ${tasks?.jewelleryOrderTask}`;
  const totalBarTasks = `Total BarOrder ${tasks?.barOrderTask}`;

  return (
    <>
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6} md={3}>
        <AppWidgetSummary
          title="Total No. of Jewellery"
          total={data?.JewelleryInventory}
          icon={'game-icons:necklace-display'}
        />
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <AppWidgetSummary title="Total No. of Silver" total={data?.Silver} color="info" icon={'mdi:gold'} />
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <AppWidgetSummary title="Total No. of Watch" total={data?.Watch} color="warning" icon={'bi:watch'} />
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <AppWidgetSummary
          title="Total No. of Customer"
          total={data?.Customer}
          color="error"
          icon={
            'streamline:interface-help-customer-support-2-customer-headphones-headset-help-microphone-phone-person-support'
          }
        />
      </Grid>

      {/* sales chart  */}
      {sales && (
        <Grid item xs={12} md={6} lg={4}>
          <AppCurrentVisits
            title={totalSales}
            chartData={[
              { label: 'Jewellery Orders', value: Number(sales?.billingTotal[0]?.total?.toFixed(2)) },
              { label: 'Bar Orders', value: Number(sales?.barBillingTotal[0]?.total?.toFixed(2)) },
              { label: 'Jewellery', value: Number(sales?.jewelleryInventoryBillingTotal[0]?.total?.toFixed(2)) },
              { label: 'Watch', value: Number(sales?.watchInventoryBillingTotal[0]?.total?.toFixed(2)) },
              { label: 'Silver', value: Number(sales?.silverInventoryBillingTotal[0]?.total?.toFixed(2)) },
            ]}
            chartColors={[
              theme.palette.primary.main,
              theme.palette.info.main,
              theme.palette.warning.main,
              theme.palette.success.main,
              theme.palette.error.main,
            ]}
          />
        </Grid>
      )}

      {/* jewelleryorder tasks */}
      {tasks && (
        <Grid item xs={12} md={6} lg={4}>
          <AppCurrentVisits
            title={totalJewelleryTasks}
            chartData={[
              { label: 'Completed Tasks', value: tasks?.jewelleryOrderCompleted },
              { label: 'Incompleted Tasks', value: tasks?.jewelleryOrderTask - tasks?.jewelleryOrderCompleted },
            ]}
            chartColors={[theme.palette.primary.main, theme.palette.error.main]}
          />
        </Grid>
      )}

      {/* barOrder tasks */}
      {tasks && (
        <Grid item xs={12} md={6} lg={4}>
          <AppCurrentVisits
            title={totalBarTasks}
            chartData={[
              { label: 'Completed Tasks', value: tasks?.barOrderCompleted },
              { label: 'Incompleted Tasks', value: tasks?.barOrderTask - tasks?.barOrderCompleted },
            ]}
            chartColors={[theme.palette.primary.main, theme.palette.error.main]}
          />
        </Grid>
      )}
  
    </Grid>
{/* legacy links  */}

<LegacyLinks links={links} />

</>
  );
};

export default DashboardAdminPage;
