import { createContext, useState } from 'react';
import PropTypes from 'prop-types';

export const OrderBillingContext = createContext(null);

export const ProcessToBillingContext = ({ children }) => {
  const [billingDetails, setBillingDetails] = useState({});

  return (
    <OrderBillingContext.Provider value={{ billingDetails, setBillingDetails }}>
      {children}
    </OrderBillingContext.Provider>
  );
};

ProcessToBillingContext.propTypes = {
  children: PropTypes.any,
};
