import {
  Box,
  Button,
  Checkbox,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import { filter } from 'lodash';
import PropTypes from 'prop-types';
import { useContext, useState } from 'react';
import Iconify from '../components/iconify/Iconify';
import Scrollbar from '../components/scrollbar';
import { AuthContext } from '../context/AuthContext';

const visuallyHidden = {
  border: 0,
  margin: -1,
  padding: 0,
  width: '1px',
  height: '1px',
  overflow: 'hidden',
  position: 'absolute',
  whiteSpace: 'nowrap',
  clip: 'rect(0 0 0 0)',
};

function getComparator(order, orderBy) {
  return (a, b) => {
    if (orderBy === 'code') {
      // If sorting by itemCode, parse the values as integers
      const itemCodeA = parseInt(a[orderBy], 10);
      const itemCodeB = parseInt(b[orderBy], 10);

      if (itemCodeA < itemCodeB) {
        return order === 'asc' ? -1 : 1;
      }
      if (itemCodeA > itemCodeB) {
        return order === 'asc' ? 1 : -1;
      }
    } else {
      // For other columns, use the default string comparison logic
      if (a[orderBy] < b[orderBy]) {
        return order === 'asc' ? -1 : 1;
      }
      if (a[orderBy] > b[orderBy]) {
        return order === 'asc' ? 1 : -1;
      }
      return 0;
    }
    return 0;
  };
}

function applySortFilter(array, comparator, query, searchQueryName) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  if (query) {
    // Check if the query can be converted to a number
    const queryNumber = parseFloat(query);

    if (!Number.isNaN(queryNumber)) {
      return filter(array, (_user) => {
        // Convert the property value to a string and compare it to the query
        const propValue = String(_user?.[searchQueryName]);
        return propValue?.toLowerCase()?.includes(queryNumber.toString().toLowerCase());
      });
    }
    // If the query is not a number, perform a regular case-insensitive string search
    return filter(array, (_user) => {
      const propValue = String(_user?.[searchQueryName]);
      return propValue?.toLowerCase()?.includes(query.toLowerCase());
    });
  }
  return stabilizedThis?.map((el) => el[0]);
}

const StyledRoot = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3),
}));

const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': {
    width: 320,
    boxShadow: theme.customShadows.z8,
  },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
  },
}));

// selected view component and search component
export const ListToolbar = ({ selected, filterName, handleFilterByName, handleSubmit, recover }) => {
  const { authData } = useContext(AuthContext);
  return (
    <StyledRoot
      sx={{
        ...(selected?.length > 0 && {
          color: 'primary.main',
          bgcolor: 'primary.lighter',
        }),
      }}
    >
      {selected?.length > 0 ? (
        <Typography component="div" variant="subtitle1">
          {selected?.length} selected
        </Typography>
      ) : (
        <StyledSearch
          value={filterName}
          onChange={handleFilterByName}
          placeholder="Search ..."
          startAdornment={
            <InputAdornment position="start">
              <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
            </InputAdornment>
          }
        />
      )}

      {selected > 0 && authData?.role === 'admin' ? (
        recover ? (
          <Tooltip title="Delete">
            <Button onClick={handleSubmit} sx={{ backgroundColor: 'green', padding: '10px 24px', color: '#ffffff' }}>
              Recover
            </Button>
          </Tooltip>
        ) : (
          <Tooltip title="Delete">
            <Button onClick={handleSubmit} sx={{ backgroundColor: 'red', padding: '10px 24px', color: '#ffffff' }}>
              Delete
            </Button>
          </Tooltip>
        )
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <Iconify icon="ic:round-filter-list" />
          </IconButton>
        </Tooltip>
      )}
    </StyledRoot>
  );
};

ListToolbar.propTypes = {
  selected: PropTypes.number,
  filterName: PropTypes.string,
  handleFilterByName: PropTypes.func,
  handleSubmit: PropTypes.func,
  recover: PropTypes.bool
};

const useTable = (headLabel, userData, queryName, statusList, datas, show, factoryList) => {
  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [searchQueryName, setSearchQueryName] = useState(queryName);

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - userData?.length) : 0;

  const filteredUsers = applySortFilter(userData, getComparator(order, orderBy), filterName, searchQueryName);

  const isNotFound = !filteredUsers?.length && !!filterName;

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
    setSearchQueryName(property);
  };

  // table container
  const TblContaiber = ({ children }) => (
    <Scrollbar>
      <TableContainer sx={{ minWidth: 800 }}>
        <Table>{children}</Table>
      </TableContainer>
    </Scrollbar>
  );

  TblContaiber.propTypes = {
    children: PropTypes.any,
  };

  // for status chage in table
  const handleStatusChange = (e) => {
    console.log(e.target.value);
    if (e.target.value !== '') {
      setSearchQueryName('status');
      setFilterName(e.target.value);
    } else {
      setFilterName('');
      setSearchQueryName('invoice');
    }
  };

  const onSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = datas?.map((n) => ({ id: n._id, name: n.name }));
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleFactoryChange = (e) => {
    console.log(e.target.value);
    if (e.target.value !== '') {
      setSearchQueryName('factoryName');
      setFilterName(e.target.value);
    } else {
      setFilterName('');
      setSearchQueryName('invoice');
    }
  };

  // table header
  const TblHead = () => (
    <TableHead>
      <TableRow>
        {show && (
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={selected?.length > 0 && selected?.length < datas?.length}
              checked={datas?.length > 0 && selected?.length === datas?.length}
              onChange={onSelectAllClick}
            />
          </TableCell>
        )}
        {headLabel.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.alignRight ? 'right' : 'left'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.label === 'Status' ? (
              <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                <InputLabel id="demo-select-small-label">Status</InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  //  value={age}
                  label="Age"
                  onChange={handleStatusChange}
                >
                  <MenuItem value="">
                    <em>Select Status</em>
                  </MenuItem>
                  {statusList?.map((status) => (
                    <MenuItem value={status?.value} key={status?.value}>
                      {status?.value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : headCell.label === 'Factory' ? (
              <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                <InputLabel id="demo-select-small-label">Factory</InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  //  value={age}
                  label="Age"
                  onChange={handleFactoryChange}
                >
                  <MenuItem value="">
                    <em>Select Factory</em>
                  </MenuItem>
                  {factoryList?.map((status) => (
                    <MenuItem value={status?.name} key={status?.name}>
                      {status?.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : (
              <TableSortLabel
                hideSortIcon
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box sx={{ ...visuallyHidden }}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</Box>
                ) : null}
              </TableSortLabel>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );

  const TblPagination = () => (
    <TablePagination
      rowsPerPageOptions={[5, 10, 25]}
      component="div"
      count={userData?.length}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  );

  const tableDataAfterPagingAndSorting = () =>
    filteredUsers?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return {
    TblContaiber,
    TblHead,
    tableDataAfterPagingAndSorting,
    TblPagination,
    selected,
    setSelected,
    emptyRows,
    isNotFound,
    filterName,
    handleFilterByName,
    setSearchQueryName,
    setFilterName,
  };
};

export default useTable;
