import api from '../axios/api';

export const getAllUsers = async () => {
  const { data } = await api.get('users');
  return data;
};

export const getAllActiveUsers = async () => {
  const { data } = await api.get('getActiveUserList');
  return data;
};

export const createuser = async (user) => {
  const { data } = await api.post('users', user);
  return data;
};

export const getSingleUser = async ({ queryKey }) => {
  const [_key, { id }] = queryKey;
  const { data } = await api.get(`users/${id}`);
  return data;
};

export const updateUser = async ({ id, user }) => {
  const { data } = await api.put(`users/${id}`, user);
  return data;
};

export const deleteUser = async ({userId, user}) => {
  const { data } = await api.post(`users/${userId}/deleteUser`, user);
  return data;
};

export const deleteMultipleUsers = async ({ids, user}) => {
  const userDetails = {ids, user}
  const { data } = await api.post(`users/deleteMany`,userDetails);
  return data;
};
