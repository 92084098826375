import api from '../axios/api';

export const getAllJewelleryOrder = async () => {
  const { data } = await api.get('jewelleryOrder');
  return data;
};

export const createJewelleryOrder = async ({ customer, orders }) => {
  const { data } = await api.post('jewelleryOrder', { customer, orders });
  return data;
};

export const getSingleJewelleryOrder = async ({ queryKey }) => {
  const [_key, { id }] = queryKey;
  const { data } = await api.get(`jewelleryOrder/${id}`);
  return data;
};

export const getSingleJewelleryOrderEdited = async ({ queryKey }) => {
  const [_key, { id }] = queryKey;
  const { data } = await api.get(`jewelleryOrder/${id}/edited`);
  return data;
};

export const cancellJewelleryOrder = async (id) => {
  const { data } = await api.post(`jewelleryOrder/${id.id}/cancel`,id.user);
  return data;
};

export const updateJewelleryOrder = async ({ id, jewelleryOrder }) => {
  const { data } = await api.put(`jewelleryOrder/${id}`, jewelleryOrder);
  return data;
};

export const getCustomerList = async () => {
  const { data } = await api.get('getCustomerList');
  return data;
};

export const deleteJewelleryOrder = async ({userId, user}) => {
  const { data } = await api.post(`jewelleryOrder/${userId}/deleteOrder`,user);
  return data;
};

export const getJewelleryOrderExcel = async (from, to , status) => {
  const { data } = await api.get(`jewelleryOrder/getJewelleryOrderExcel?from=${from}&to=${to}?status=${status}`);
  return data;
};
