import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button, Card, Stack, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import useTable, { ListToolbar } from '../../hooks/useTable';
import Scrollbar from '../../components/scrollbar/Scrollbar';
import NoResultsTable from '../../components/noResultsTable/NoResultsTable';

const headLabel = [
  { id: 'invoice', label: 'Invoice No', alignRight: false },
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'customerName', label: 'Customer Name', alignRight: false },
  { id: '', label: 'Actions' },
];

const DashboardUserBarComplete = ({ completedTask }) => {
  const navigate = useNavigate();
  const {
    TblContaiber,
    TblHead,
    tableDataAfterPagingAndSorting,
    TblPagination,
    selected,
    emptyRows,
    isNotFound,
    filterName,
    handleFilterByName,
  } = useTable(headLabel, completedTask, 'invoice');

  return (
    <Card>
      <ListToolbar selected={selected.length} filterName={filterName} handleFilterByName={handleFilterByName} />
      <Scrollbar>
        <TblContaiber>
          <TblHead />
          <TableBody>
            {tableDataAfterPagingAndSorting()?.map((user) => {
              const { _id, name, invoice, customerName } = user;
              const selectedUser = selected.indexOf(name) !== -1;

              return (
                <TableRow hover key={_id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                  {/* <TableCell padding="checkbox">
                  <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, name)} />
                </TableCell> */}
                  <TableCell padding="normal" component="th" scope="row">
                    <Stack direction="row" alignItems="center" spacing={2}>
                      {/* <Avatar alt={name} src={avatarUrl} /> */}
                      <Typography variant="subtitle2" noWrap>
                        {invoice}
                      </Typography>
                    </Stack>
                  </TableCell>

                  <TableCell align="left">{name}</TableCell>

                  <TableCell align="left">{customerName}</TableCell>

                  <TableCell align="left">
                    <Button
                      color="success"
                      onClick={() => navigate(`/dashboard/order/bar/${_id}/view`, { replace: true })}
                    >
                      view
                    </Button>{' '}
                  </TableCell>
                </TableRow>
              );
            })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          {isNotFound && <NoResultsTable filterName={filterName} />}
        </TblContaiber>
      </Scrollbar>
      <TblPagination />
    </Card>
  );
};

DashboardUserBarComplete.propTypes = {
  completedTask: PropTypes.array,
};

export default DashboardUserBarComplete;
