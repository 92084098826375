import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
// @mui
import { Button, Container, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

const ErrorPageRedirection = ({ resetError }) => {
  const navigate = useNavigate();
  return (
    <>
      <Helmet>
        <title> OOPS something went wrong </title>
      </Helmet>

      <Container>
        <StyledContent sx={{ textAlign: 'center', alignItems: 'center' }}>
          <Typography variant="h3" paragraph>
            Sorry, something went wrong!
          </Typography>

          <Typography sx={{ color: 'text.secondary' }} variant="h1">
            OOPS!
          </Typography>

          <Button
            onClick={() => {
              resetError();
              navigate('/');
            }}
            size="large"
            variant="contained"
          >
            Go to Home
          </Button>
        </StyledContent>
      </Container>
    </>
  );
};

export default ErrorPageRedirection;
